module.exports = {
  // devServer: {
  //   proxy: {
  //     '/api': {
  //       target: 'http://192.168.0.51:37494/api',
  //       ws: false,
  //       changeOrigin: true
  //     }
  //   },
  //   open: true,
  //   host: '192.168.0.51',
  //   port: 8090
  // }
  devServer: {
    proxy: {
      '/api': {
        target: 'http://test.yundiankong.com/api',
        ws: false,
        changeOrigin: true,
        pathRewrite: {
          '^/api': ''
        }
      }
    },
    open: true,
    host: 'test.yundiankong.com',
    port: 80
  }
}
