import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '*',
    redirect: '/Login',
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/datascreen/:token',
    name: 'datascreen',
    component: () => import('../views/DataScreen.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    redirect: '/homepage', // 访问home，重定向，显示主页
    children:[{
      path: '/homepage',
      name: 'homepage',
      component: () => import('../views/HomePage.vue'),
      meta: {
        title: '主页'
      }
    },
    {
      path: '/group',
      name: 'group',
      component: () => import('../views/sys/Group.vue'),
      meta: {
        title: '角色管理'
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('../views/sys/User.vue'),
      meta: {
        title: '管理员'
      }
    },
    {
      path: '/log_opt',
      name: 'log_opt',
      component: () => import('../views/log/LogOpt.vue'),
      meta: {
        title: '操作日志'
      }
    },
    {
      path: '/company',
      name: 'company',
      component: () => import('../views/car/Company.vue'),
      meta: {
        title: '公司管理'
      }
    },
    {
      path: '/emp',
      name: 'emp',
      component: () => import('../views/car/Emp.vue'),
      meta: {
        title: '员工管理'
      }
    },
    {
      path: '/car',
      name: 'car',
      component: () => import('../views/car/Car.vue'),
      meta: {
        title: '车辆设置'
      }
    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import('../views/car/Apply.vue'),
      meta: {
        title: '资料申请'
      }
    },
    {
      path: '/storage_location',
      name: 'storage_location',
      component: () => import('../views/location/StorageLocation.vue'),
      meta: {
        title: '消纳点管理'
      }
    },
    {
      path: '/temp_location',
      name: 'temp_location',
      component: () => import('../views/location/TempLocation.vue'),
      meta: {
        title: '临时消纳点管理'
      }
    },
    {
      path: '/building_location',
      name: 'building_location',
      component: () => import('../views/location/BuildingLocation.vue'),
      meta: {
        title: '工地管理'
      }
    },
    {
      path: '/enter_park',
      name: 'enter_park',
      component: () => import('../views/log/LogEnter.vue'),
      meta: {
        title: '入场记录'
      }
    },
    {
      path: '/exit_park',
      name: 'exit_park',
      component: () => import('../views/log/LogExit.vue'),
      meta: {
        title: '出场记录'
      }
    },
    {
      path: '/transport_count',
      name: 'transport_count',
      component: () => import('../views/log/LogTransportCount.vue'),
      meta: {
        title: '出场记录'
      }
    },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  document.title = '智慧城市车辆管理综合管理平台'
  if (to.path === '/') return next()
  if(to.path.indexOf('datascreen')) return next()
  var session = window.sessionStorage.getItem('rs_login_token')
  if (!session) { return next('/') } else { next() }
})

export default router
