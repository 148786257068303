import axios from 'axios'
import attr from '../../vue.config'
import { MessageBox } from 'element-ui'




export async function get(Url,param){
  axios.defaults.headers.common.token = window.sessionStorage.getItem('rs_login_token')
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: attr.devServer.proxy['/api'].target + '/' + Url,
      params: param,
      timeout: 10000,
      traditional: true,
      Headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data)
    }).catch(function (error) {
        if (error.response.status === 401){
            MessageBox.alert('用户登录已过期', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  window.location.href = '/'
                  window.sessionStorage.removeItem('rs_login_token')// token
                  window.localStorage.removeItem('rs_login_info')// 用户登录信息
                }
              })
        } 
        else {
            reject(res.data)
        }
    })
  })
}


export async function post(Url,param){
    axios.defaults.headers.common.token = window.sessionStorage.getItem('rs_login_token')
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: attr.devServer.proxy['/api'].target + '/' + Url,
        data: param,
        timeout: 10000,
        traditional: true,
        Headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        resolve(res.data)
      }).catch(function (error) {
        if (error.response.status === 401){
            MessageBox.alert('用户登录已过期', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  window.location.href = '/'
                  window.sessionStorage.removeItem('rs_login_token')// token
                  window.localStorage.removeItem('rs_login_info')// 用户登录信息
                }
              })
        }
        else {
            reject(res.data)
        }
      })
    })
    
}


export function FommatTreeData(arrlist, pid, id){
  const listOjb = {} // 用来储存{key: obj}格式的对象
  const treeList = []
  // 将数据变换成{key: obj}格式，方便下面处理数据

  for (let i = 0; i < arrlist.length; i++) {
    listOjb[arrlist[i][id]] = arrlist[i]
  }
  // 根据pid来将数据进行格式化
  for (let j = 0; j < arrlist.length; j++) {
    // 判断父级是否存在
    const haveParent = listOjb[arrlist[j][pid]]
    if (haveParent) {
      // 如果有没有父级children字段，就创建一个children字段
      !haveParent.children && (haveParent.children = [])
      // 在父级里插入子项
      haveParent.children.push(arrlist[j])
    } else {
      // 如果没有父级直接插入到最外层
      treeList.push(arrlist[j])
    }
  }
  return treeList
}


// 输出格式化时间
export function FormateDate (time, typ) {
  if(time===undefined||time===null)
    return ''
  else{
    var date = new Date(time)
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    var t1 = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    var t2 = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
    if (typ === 0) { return t1 }
    if (typ === 1) { return t2 }
    if (typ === 2) { return t1 + ' ' + t2 }
  }
}
