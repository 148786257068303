import Vue from 'vue'
import Vuex from 'vuex'
import {get,post,FommatTreeData} from '../util/util'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    UserDataList:[],
    CompanyDataList:[],
    GroupDataList:[],
    DeptDataList:[],
    DeptTreeData:[],

    EmpDataList:[],
    EmpDataTotal:0,

    CarDataList:[],
    CarDataTotal:0,

    LocationDataList:[],
    GateDataList:[]

  },
  mutations: {
    userDataListMutations(state,data){
      state.UserDataList=data
    },
    CompanyDataListMutations(state,data){
      state.CompanyDataList=data
    },
    GroupDataListMutations(state,data){
      state.GroupDataList=data
    },
    DeptDataListMutations(state,data){
      state.DeptDataList=data
      state.DeptTreeData= FommatTreeData(data, 'dept_sub_id', 'dept_id')
    },
    EmpDataListMutations(state,data){
      state.EmpDataList=data.list
      state.EmpDataTotal=data.total
    },
    CarDataMutations(state,data){
      state.CarDataList=data.list
      state.CarDataTotal=data.total
    },
    LocationDataMutations(state,data){
      state.LocationDataList=data
    },
    GateDataMutations(state,data){
      state.GateDataList=data
      
    }
  },
  actions: {
    //操作员
    getUserDataList(store,payload){
      get('user/getuserdatalist',payload).then(res=>{
        store.commit('userDataListMutations',res.list)
      })
    },

    //公司
    getCompanyDataList(store){
      get('company/getcompanydatalist').then(res=>{
        store.commit('CompanyDataListMutations',res.list)
      })
    },

    //用户组
    getGroupDataList(store){
      get('group/getgroupdatalist').then(res=>{
        store.commit('GroupDataListMutations',res.list)
      })
    },

    //人员
    getEmpDataList(store,payload){
      get('emp/getempdatalist',payload).then(res=>{
        store.commit('EmpDataListMutations',{list:res.list,total:res.total})
      })
    },

    //车辆
    getCarDataList(store,payload){
      get('car/getcardatalist',payload).then(res=>{
        store.commit('CarDataMutations',{list:res.list,total:res.total})
      })
    },

    //消纳点/工地
    getLocationDataList(store,payload){
      get('location/getLocationDataList').then(res=>{
        let list=res.list
        if(payload||payload>-1)
        {
          list=list.filter(item=>item.lt_type===payload)
        }
        
        store.commit('LocationDataMutations',list)
      })
    },

    //通道
    getGateDataList(store){
       get('gate/getGateDataList').then(res=>{
        store.commit('GateDataMutations',res.list)
      })
    }
  },
  modules: {
  }
})
